<template>
  <div class="registration pb-4">
    <div class="d-flex justify-content-between">
      <h3 class="pb-3 px-1 mb-0">Add Facility</h3>
    </div>
    <AddFacility :customer="2" @submit="create" />
  </div>
</template>
<script>
import AddFacility from "@/containers/Facility/AddFacility";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    AddFacility
  },
  name: "FacilityAdd",
  computed: {
    ...mapGetters(["getCustomerId"])
  },
  methods: {
    ...mapActions(["addFacility"]),
    create(facility) {
      let data = {
        facility_name: facility.facility_name,
        organisation_type_id: facility.category_id,
        email: facility.email,
        location_id: facility.location_id,
        first_name: facility.first_name,
        surname: facility.last_name,
        username: facility.username,
        password: facility.password,
        customer_id: this.getCustomerId,
        government: facility.type === 3 || facility.type === 1,
        private: facility.type === 3 || facility.type === 2,
        group: facility.group,
        website: "",
        address: "",
        no_of_beds: 0
      };
      this.addFacility(data);
    }
  }
};
</script>
