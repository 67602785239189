<template>
<div>
  <LoadingOverlay v-if="isFetching" />
  <div class="addHospital pb-4" v-if="!isFetching">
     <ValidationObserver ref="addFacility" v-slot="{ handleSubmit }">
      <form id="addFacility" @submit.prevent="handleSubmit(onSubmit)">
        <CCardGroup class="has-loading-overlay">
          <CCard class="p-2">
            <CCardBody>
              <h4 class="pb-3 px-1 mb-0 underline">Facility</h4>
              <CRow class="row mb-3">
                <label class="required col-lg-3 col-md-12">Facility Name</label>
                <div class="col-lg-4 col-md-6 col-sm-12">
                  <ValidationProvider
                    :rules="{ required: true, facility_name: 100 }"
                    v-slot="{errors}"
                  >
                    <TextInput
                      name="facility_name"
                      :value="facilities.facility_name"
                      @input="handleInput"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </CRow>
              <CRow class="row mb-3">
                <label class="required col-lg-3 col-md-12">Category</label>
                <div class="col-lg-4 col-md-6 col-sm-12">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <Select
                      name="category_id"
                      :value="facilities.category_id"
                      @input="handleChangeSelect"
                      :options="options && options['category'] ? options['category'] : []"
                      :taggable="false"
                      :multiple="false"
                      :clearable="true"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </CRow>
              <CRow class="row mb-3">
                <label class="required col-lg-3 col-md-12">Is this a Group Facility</label>
                <div class="col-lg-4 col-md-6 col-sm-12">
                  <ValidationProvider rules="required" v-slot="{errors}">
                  <RadioButton
                    name="group"
                    :value="facilities.group"
                    :options="options && options['group'] ? options['group'] : []"
                    @change="handleChangeRadio"
                    :error="errors[0]"
                  />
                  </ValidationProvider>
                </div>
              </CRow>
              <CRow class="row mb-3">
                <label class="required col-lg-3 col-md-12">Location</label>
                <div class="col-lg-4 col-md-6 col-sm-12">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <Select
                      name="location_id"
                      :value="facilities.location_id"
                      @input="handleChangeSelect"
                      :options="options && options['locations'] ? options['locations'] : []"
                      :taggable="false"
                      :multiple="false"
                      :clearable="true"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </CRow>
              <CRow class="row mb-3">
                <label class="required col-lg-3 col-md-12">Type</label>
                <div class="col-lg-4 col-md-6 col-sm-12">
                  <ValidationProvider rules="required" v-slot="{errors}">
                  <RadioButton
                    name="type"
                    :value="facilities.type"
                    :options="options && options['type'] ? options['type'] : []"
                    @change="handleChangeRadio"
                    :error="errors[0]"
                  />
                  </ValidationProvider>
                </div>
              </CRow>
              <CRow class="border-top">
                <h4 class="pt-2 pb-3 px-1 mb-0 underline">Main Contact</h4>
              </CRow>
              <CRow class="row mb-3">
                <label class="required col-lg-3 col-md-12">First Name</label>
                <div class="col-lg-4 col-md-6 col-sm-12">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <TextInput
                      name="first_name"
                      :value="facilities.first_name"
                      @input="handleInput"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </CRow>
              <CRow class="row mb-3">
                <label class="required col-lg-3 col-md-12">Last Name</label>
                <div class="col-lg-4 col-md-6 col-sm-12">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <TextInput
                      name="last_name"
                      :value="facilities.last_name"
                      @input="handleInput"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </CRow>
              <CRow class="row mb-3">
                <label class="required col-lg-3 col-md-12">Email</label>
                <div class="col-lg-4 col-md-6 col-sm-12">
                  <ValidationProvider rules="required|email" v-slot="{errors}">
                    <TextInput
                      name="email"
                      :value="facilities.email"
                      @input="handleInput"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </CRow>
              <CRow class="row mb-3">
                <label class="required col-lg-3 col-md-12">User Name
                  <span class="sm-title">(Autogenerated)</span>
                </label>
                <div class="col-lg-4 col-md-6 col-sm-12">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <TextInput
                      name="username"
                      :value="facilities.username"
                      @input="handleInput"
                      :disabled=true
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </CRow>
              <CRow class="row mb-3">
                <label class="required col-lg-3 col-md-12">Password
                  <span class="sm-title">(Autogenerated)</span>
                </label>
                <div class="col-lg-4 col-md-6 col-sm-12 mb-1">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <TextInput
                      name="password"
                      type="password"
                      :value="facilities.password"
                      @input="handleInput"
                      :disabled="isResetPassword"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-12">
                 <CButton
                    color="primary"
                    class="mb-1"
                    @click="resetPassword()"
                  >Reset Password</CButton>
              </div>
              </CRow>
            </CCardBody>
          </CCard>
        </CCardGroup>
        <div class="text-center mx-0 mt-4 pt-4 pb-4" style="padding-bottom:150px!important">
          <button class="btn rounded-0 px-5 bg-primary btn-lg" type="submit">Register</button>
        </div>
      </form>
     </ValidationObserver>
  </div>
</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Vue from "vue";
import m from "moment";
import { times } from "lodash";
import { extend } from "vee-validate";
import { required, email, confirmed, min, max } from "vee-validate/dist/rules";

import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import PhoneInput from "@/components/reusable/Fields/PhoneInput";
import RadioButton from "@/components/reusable/Fields/RadioButton";

extend("required", { ...required, message: "This field is required" });
extend("facility_name", { ...max, message: "Max length: 100 character" });
extend("email", { ...email, message: "Invalid email" });

export default {
  components: {
    Select,
    TextInput,
    RadioButton,
    PhoneInput
  },
  computed: {
    ...mapGetters(["getFacilityLocations","getOrganisationTypeList","credentialDetails"]),
    isFetching(){
      if(Object.keys(this.credentialDetails).length){
        this.handleInput('username',this.credentialDetails.username);
        this.handleInput('password',this.credentialDetails.password);
        return false;
      }
      return true;
    },
    options() {
      return {
        locations: this.getFacilityLocations || [],
        type: [
          { id: 1, label: "Goverment" },
          { id: 2, label: "Private" },
          { id: 3, label: "Both" },
        ],
        group:[
          { id: true, label: "Yes"},
          { id: false, label: "No"}
        ],
        category : this.getOrganisationTypeList || []
      };
    }
  },
  data() {
    return {
      isFileError: false,
      facilities: {},
      payload: {},
      isResetPassword: true
    };
  },
  methods:{
     ...mapActions([
      "scrollTop",
      "fetchOrganisationTypeList",
      "fetchFacilityLocations",
      "fetchCredentialDetails",
      "showToast"
    ]),
    handleInput(name, value) {
      Vue.set(this.facilities, name, value);
      Vue.set(this.payload, name, value);
    },
    handleChangeSelect(name, value) {
      Vue.set(this.facilities, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null
      };
    },
    handleChangeRadio(name, value) {
      let val = null;
      if (value.id === false || value.code === false) {
        val = false;
      } else {
        val = value.id || value.code || value;
      }
      Vue.set(this.facilities, name, value);
      this.payload = {
        ...this.payload,
        [name]: val
      };
    },
    resetPassword(){
      this.isResetPassword=false;
      this.handleInput('password',null);
    },
    async onSubmit() {
      const isValid = await this.$refs.addFacility.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!"
        });
        return;
      }
      this.$emit("submit", this.payload);
    }
  },
  mounted(){
    this.scrollTop();
    this.fetchFacilityLocations();
    this.fetchOrganisationTypeList();
    this.fetchCredentialDetails();
  }
};
</script>

<style lang="scss" scoped>
.underline{
  text-decoration: underline;
}
.sm-title{
  font-size:0.8rem
}
</style>

